import IncidentTab from '@/components/incidents/elements/IncidentTab/IncidentTab.vue';
import IncidentConfirmTab from '@/components/incidents/elements/IncidentConfirmTab/IncidenteConfirmTab.vue';

export default {
	name: 'IncidentList',
	data() {
		return {
			tab: 0,
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Tickets',
					link: true,
					exact: true,
					disabled: true,
				},
			],
		};
	},
	watch: {
		tab(newTab) {
			this.$nextTick(() => {
				if (newTab === 0 && this.$refs.incidentTab) {
					this.$refs.incidentTab.getTickets();
				} else if (newTab === 1 && this.$refs.incidentConfirmTab) {
					this.$refs.incidentConfirmTab.getTickets();
				}
			});
		},
	},
	computed: {},
	created() {},
	methods: {},
	beforeDestroy() {},
	components: {
		IncidentTab,
		IncidentConfirmTab,
	},
};
