import { mapActions, mapState } from 'vuex';
import CalificationTicket from '@/components/incidents/elements/CalificationsTicket/Calificationticket.vue';
import RatingDialog from '@/components/request/elements/RatingDialog/RatingDialog.vue';
import moment from 'moment';
import { getIncidentStatus } from '@/helpers/statusIncident.js';
import { showError } from '@/helpers/globalHelpers';

export default {
    name: "IncidentTab",
    data() {
		return {
			tab: 0,
			ticketSelected: null,
			searchTimeout: null,
			selectedStatus: null,
			filterActive: false,
			calificationTicketDialog: false,
			item: {},
			searchValue: '',
			loading: false,
			status: [
				{ name: 'SOLICITADO' },
				{ name: 'RESUELTO' },
				{ name: 'EN ATENCION' },
				{ name: 'CONSULTA' },
				{ name: 'CERRADO' },
				{ name: 'CALIFICADO' },
				{ name: 'PENDIENTE AP' },
			],
			ticketFilters: {
				page: 1,
				limit: process.env.VUE_APP_PAGINATION_PER_PAGE || 20,
				pagination: true,
				generic: false,
				num_ticket: null,
				status: null,
				area: null,
			},
			breadcrumbs: [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: 'Incidentes',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'IncidentsHome',
					},
				},
				{
					text: 'Tickets',
					link: true,
					exact: true,
					disabled: true,
				},
			],
			headers: [
				{ text: 'Número de Ticket', sortable: false, value: 'num_ticket' },
				{
					text: 'Área',
					sortable: false,
					value: 'subtype.classification.area.name',
				},
				{ text: 'Subtipo', sortable: false, value: 'subtype.name' },
				{
					text: 'Responsable',
					sortable: false,
					value: 'responsible_id.fullName',
				},
				{
					text: 'Creador',
					sortable: false,
					value: 'creator_id.fullName',
				},
				{ text: 'Fecha de registro', sortable: false, value: 'created_at' },
				{
					text: 'Estado',
					sortable: false,
					value: 'status',
					align: 'center',
				},
				{ text: 'Acciones', value: 'actions', sortable: false },
			],
			ticketLimits: [10, 20, 50, 80],
		};
	},
	computed: {
		...mapState('ticket', ['tickets', 'ticketsPagination', 'unqualified']),
		...mapState('area', ['allAreas']),
	},
	created() {
		this.getTickets();
		this.listAllAreas();
	},
	methods: {
		getIncidentStatus,

		...mapActions('area', ['listAllAreas']),
		...mapActions('ticket', ['listTicketForUsers', 'cleanTickets']),

		async getTickets() {
			this.loading = true;
			const { error } = await this.listTicketForUsers(this.ticketFilters);
			if (error) showError(error);
			this.loading = false;
		},
		async searchStatusTicket() {
			this.ticketFilters.status = 'CERRADO';
			this.ticketFilters.page = 1;
			this.filterActive = true;
			this.getTickets();
		},
		async searchTickets() {
			this.ticketFilters.status = this.selectedStatus;
			this.ticketFilters.page = 1;
			await this.getTickets();
		},
		formatDate(dateString) {
			if (!dateString) {
				return '-';
			}
			return moment(dateString).format('DD/MM/YYYY HH:mm');
		},
		debounceSearch() {
			clearTimeout(this.searchTimeout);
			this.searchTimeout = setTimeout(() => {
				this.ticketFilters.num_ticket = this.searchValue;
				this.getTickets();
			}, 800);
		},
		clearFilter() {
			this.ticketFilters.status = null;
			this.ticketFilters.page = 1;
			this.filterActive = false;
			this.getTickets();
		},
		calificationTicket(item) {
			this.item = Object.assign({}, item);
			this.calificationTicketDialog = true;
			this.$nextTick(() => {
				document.activeElement.blur();
			});
		},
		showRating(ticketId) {
			this.calificationTicketDialog = true;
			this.ticketSelected = ticketId;
		},
	},
	beforeDestroy() {
		this.cleanTickets();
	},
	components: {
		CalificationTicket,
		RatingDialog,
	},
  };